:root {
	--results-height: 710px;
}

h1, h2, h3, h4, nav, summary, button#start {
	font-weight: bold;
}

@media (min-width: 700px) {
	#about[open] {
		height: 443px;
	}
}

@media (min-width: 500px) {
	#settings[open] {
		height: 378px;
	}
}

main, footer > :not(#language-list) {
	direction: rtl;
}

sent-circle-thingy, time-circle-thingy, received-circle-thingy {
	direction: ltr;
}

button#start {
	left: 0;
	right: unset;
}